/* CSS for About component */
.about-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  text-align: center;
}

.section-title {
  color: #008080; /* Teal color for section titles */
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.section-description {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
}

/* Styling for Team Members */
.team-members {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.team-member {
  width: 300px;
  margin: 20px;
  text-align: center;
}

.team-member-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.team-member-name {
  color: #008080; /* Teal color for team member name */
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.team-member-role {
  color: #666;
  font-size: 1rem;
  margin-bottom: 10px;
}

.team-member-description {
  color: #0a0909;
  font-size: 0.9rem;
}

/* Styling for Values List */
.values-list {
  list-style: none;
  padding: 0;
}

.value-item {
  margin-bottom: 20px;
}

.value-title {
  color: #008080; /* Teal color for value titles */
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.value-description {
  color: #666;
  font-size: 1rem;
}

/* Styling for Why We Trust You? Section */
.trust-values {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.trust-values li {
  width: 45%;
  margin-bottom: 10px;
  color: #666;
}
/* CSS for About component */

/* ... (existing styles) ... */

/* Styling for h1 to resemble a button */
h1.section-title {
  display: inline-block;
  padding: 10px 20px;
  background-color: #291f49;; /* Teal color for button-like background */
  color: white;
  text-align: center;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

h1.section-title:hover {
  background-color: #005454; /* Darker teal color on hover */
}
h2.section-title {
  display: inline-block;
  padding: 10px 20px;
  background-color: #291f49; /* Teal color for button-like background */
  color: white;
  text-align: center;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

h2.section-title:hover {
  background-color: #005454; /* Darker teal color on hover */
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .team-member {
    width: 100%;
  }

  .team-member-image {
    width: 250px;
    height: 215px;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .section-description {
    font-size: 0.9rem;
  }

  .value-title {
    font-size: 1.2rem;
  }

  .value-description {
    font-size: 0.9rem;
  }

  .trust-values li {
    width: 100%;
  }
}
