/* Contact.css */

.container {
  text-align: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    background-color: 	rgb(226 226 54 / 36%);
    padding: 50px;
    border-radius: 8px;
  }
  
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    grid-column: span 2;
    padding: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #555;
  }
  
  /* Add some decorations for form elements */
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    transition: border-color 0.3s ease;
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  textarea:focus {
    border-color: #f1c40f;
  }
  
  /* Responsive styles for smaller screens */
  @media screen and (max-width: 768px) {
    form {
      grid-template-columns: 1fr;
    }
    button {
      grid-column: unset;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  label {
    font-weight: bold;
  }
  
  input,
  textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    resize: vertical;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #08294e;
  }
  
  .success-message {
    color: green;
    background-color: #e8f5e9;
    padding: 10px;
    border: 1px solid #66bb6a;
    border-radius: 4px;
    margin-top: 10px;
    text-align: center;
    animation: bounce-fade-in 1s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  
  .error-message {
    color: red;
    background-color: #ffebee;
    padding: 10px;
    border: 1px solid #ef9a9a;
    border-radius: 4px;
    margin-top: 10px;
    text-align: center;
    animation: bounce-fade-in 1s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  
  @keyframes bounce-fade-in {
    0% {
      opacity: 0;
      transform: translateY(-30px) scale(0.5);
    }
    60% {
      opacity: 1;
      transform: translateY(0) scale(1.2);
    }
    100% {
      transform: translateY(0) scale(1);
    }
  }
 /* CSS for Contact Info */
.contactinfo {
  width: 300px; /* Set width as needed */
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
}

.contactinfo h1 {
  color: #333;
  margin-bottom: 20px;
}

.contactinfo p {
  color: #666;
  margin-bottom: 10px;
}

.contactinfo a {
  color: #007bff;
  text-decoration: none;
}

.contactinfo a:hover {
  text-decoration: underline;
}
