/* styles.css */

/* Reset some basic styles */
body {
    margin: 0;
    padding: 0;
  }
  
  /* Override Tailwind default form styles */
  form {
    margin: 0;
    padding: 0;
  }
  
  /* Add your own custom styles as needed */
  a{
    color: blue;
  }