/* Style for PhotoSlider component */

/* Center the entire slider content */
.PhotoSlider {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Style for images in the slider */
  .PhotoSlider img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 8px;
  }
  
  /* Style for descriptions */
  .PhotoSlider p {
    margin-top: 10px;
    font-size: 16px;
    color: #555;
  }
  /* sliderStyles.css */
/* slider.css */

/* Styles for slider images */
.slick-slide img {
    width: 100%;
    height: 200px;
    object-fit: cover; /* Ensure the image covers the entire slide */
  }
  
/* Styles for slider buttons */
.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 8px 16px;
    background-color: #4a5568; /* Change color as needed */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .slider-button-prev {
    left: 10px;
  }
  
  .slider-button-next {
    right: 10px;
  }
  @media screen and (max-width: 768px) {
    /* Adjust styles for screens smaller than 768px */
    
  }