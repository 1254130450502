/* imageuploader.css */

/* Container for the form */
.form-container {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

/* Style for file input */
input[type="file"] {
  margin-bottom: 15px;
}

/* Style for text input and textarea */
.form-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Allow vertical resizing for textarea */
}

/* Style for the button */
button {
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

/* Responsive styling for smaller screens */
@media screen and (max-width: 600px) {
  .form-container {
    width: 90%;
    margin: 0 auto;
  }
}
