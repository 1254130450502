/* NgoFamily.css */

.ngo-family {
    text-align: center;
    padding: 20px;
  }
  
  .ngo-family h2 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .ngo-family img {
    width: 100%;
    max-width: 400px; /* Increase the max-width for larger images */
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; /* Increase the margin-bottom for more space between images */
  }
  
  /* Center the images in a grid layout */
  .photo-container {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
    gap: 20px;
    justify-content: center;
  }
  