/* adminpage.css */

/* Style for the main container */
.admin-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

/* Style for user info */
.user-info {
  margin-bottom: 20px;
}

.user-info img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.user-info h2 {
  font-size: 24px;
  margin: 0;
}

/* Style for the admin page heading */
.admin-container h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

/* Style for the upload message */
.admin-container p {
  font-size: 18px;
  color: green;
  margin-bottom: 10px;
}
.mt-4{
 color: #0f9508;
}
