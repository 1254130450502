.device-selection-section {
  padding: 20px 0;
  background-color: #f8f8f8;
}

.device-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.device-links a {
  text-decoration: none;
  text-align: center;
  margin: 10px;
  width: 300px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.device-links a:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.device-links img {
  margin-bottom: 15px;
  width: 260px;
  height: 200px;
}

.device-links p {
  font-size: 16px;
  color: #333;
  
}
/* Styling for NGO Work Slider Section */
/* Styling for NGO Work Slider Section */
.ngo-work-slider {
  padding: 20px 0;
  background-color: #f8f8f8;
  text-align: center;
}

/* Styling for the heading of NGO Work Slider */
.ngo-work-slider h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Styling for each NGO work item */
.work-item {
  width: 100%; /* Full width initially */
  max-width: 300px; /* Set maximum width for larger screens */
  margin: 0 auto 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Styling for the NGO work image */
.work-item img {
  width: 100%;
  height: 200px; /* Fixed height for the images */
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Hover effect for NGO work image */
.work-item:hover img {
  transform: scale(1.05);
}
.hero-section p {
  font-size: 18px;
  margin-top: 20px;
  background-color: rgb(180 197 238 / 87%);
  padding: 10px;
  border-radius: 5px;
}
/* Styling for the description of each NGO work */
.work-description {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  text-align: center;
}
/* CSS */
.slick-arrow.slick-next {
  display: none !important;
}
.hero-section {
  background-size: cover;
  background-position: center;
  text-align: center;
  color: #fff;
  padding: 0px 0;
}

/* Styling for the title of NGO work in the description */
.work-description h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

/* Styling for the description text of NGO work */
.work-description p {
  font-size: 14px;
  color: rgb(180 197 238 / 87%);
  margin-bottom: 0;
}
/* Example Tailwind CSS classes */
.w-full {
  width: 100%;
}

.h-auto {
  height: auto;
}

.mb-4 {
  margin-bottom: 1rem;
}
/* CSS */
.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg-white {
  width: 90%; /* Adjust width as needed */
  margin: auto; /* Center horizontally */
}
.rounded-md {
  border-radius: 0.375rem; /* Adjust this value as needed */
}



.p-6 {
  padding: 1.5rem;
}

.rounded-lg {
  border-radius: 0.5rem; /* Adjust this value as needed */
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.text-xl {
  font-size: 1.25rem; /* Adjust this value as needed */
}

.font-bold {
  font-weight: 700;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.text-gray-700 {
  color: #4a5568; /* Adjust this color as needed */
}
/* FAQ Section */
/* Styling for the FAQ section */
.faq-section {
  max-width: 800px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the section horizontally */
  padding: 20px; /* Add padding around the section */
}

/* Styling for the question items */
.faq-section li {
  list-style: none; /* Remove the default list bullet */
  margin-bottom: 15px; /* Add space between each FAQ item */
}

/* Styling for the toggle button */
.faq-toggle {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0; /* Background color for the toggle button */
}

/* Styling for the expanded toggle button */
.faq-toggle.expanded {
  background-color: #ccc; /* Background color for the expanded toggle button */
}

/* Styling for the answer */
.faq-section p {
  color: #b51414;
  display: none; /* Hide the answer by default */
  margin-top: 5px; /* Add space between the question and the answer */
}

/* Styling for the visible answer */
.faq-section li.expanded p {
  display: block; /* Show the answer when expanded */
}

/* Styling for the load more button */
.load-more-button {
  display: block;
  margin-top: 15px; /* Add space between the FAQs and the button */
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #0e1f31; /* Button background color */
  color: white; /* Button text color */
}
/* Add or modify CSS for FAQ section */
/* Styling for the answer */
.answer {
  display: none;
}

.answer.show {
  display: block;
}

/* CSS for Contact Info */
.contactinfo {
  width: 300px; /* Set width as needed */
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
}

.contactinfo h1 {
  color: #333;
  margin-bottom: 20px;
}

.contactinfo p {
  color: #666;
  margin-bottom: 10px;
}

.contactinfo a {
  color: #007bff;
  text-decoration: none;
}

.contactinfo a:hover {
  text-decoration: underline;
}




.load-more-button:hover {
  background-color: #0a70dd;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .work-item {
    max-width: 100%; /* Full width for smaller screens */
  }
}